import React from "react";
import { BsPlayCircle } from 'react-icons/bs';
import { Container, Row, Col, Button } from 'react-bootstrap';
import background from '../../../../assets/images/background.jpg';
import './index.css'

export default function Front() {
    return (
        <div>
            <Container className="py-20">
            <Row className="position-relative overflow-hidden px-3 pt-4 pt-md-5 d-grid gap-3 custom-isolate custom-responsive-padding custom-grid-layout">
            <Col lg={6} className="mx-auto text-center custom-max-width custom-large">
                <h1 className="fw-bold custom-text-4xl custom-tracking-tight text-black custom-leading-10">
                    Celebration of individuality and style
                </h1>

                <p className="mt-6" style={{
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    color: "#4a5568",
                    marginTop: "2rem"
                }}>
                    At style-sync, we believe that true fashion comes from within, and it's all about embracing your unique self.
                </p>

            <div className="mt-5 d-flex align-items-center justify-content-center .get-started-div" style={{cursor: 'pointer'}}>
              <Button className="btn fw-bold px-3 py-2 get-started-btn ">
                Get started
              </Button>
              <a href="#" className="text-body play-link">
                <div className="play-link-btn display-6" style={{ cursor: 'pointer' }}>
                <BsPlayCircle />
                </div> 
              </a>
            </div>
            </Col>
            <Col>
            
            <div className="pt-lg-0 custom-isolate position-relative">
              <div className="bg-container position-relative overflow-hidden bg-white">
                <img
                  src={background}
                  alt=""
                  className="img-fluid custom-img-cover"
                />
              </div>
            </div>
            </Col>
            </Row>
            </Container>
        </div>
    )
}