import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import './ResetPassword.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [enteredCode, setEnteredCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1); // 1: Enter email, 2: Verify code, 3: Reset password
  const [success, setSuccess] = useState(false); // State variable to track success state

  const navigate = useNavigate(); // Initialize the navigate function

  const sendVerificationEmail = async () => {
    // Simulate sending verification email (replace with actual API call)
    console.log('Sending verification email to:', email);
    // Here, you would call your backend API to send the verification email
    // For now, let's assume it's successful
    setStep(2); // Move to the next step
  };

  const verifyCode = () => {
    // Validate if the entered code is 5 digits
    const codePattern = /^\d{5}$/;
    if (codePattern.test(enteredCode)) {
      setStep(3); // Move to the next step
    } else {
      setError('Invalid verification code. Please enter a 5-digit code.');
    }
  };

  const resetPassword = async () => {
    // Validate if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }
    setError('');

    // Reset password logic goes here
    // This function will be called once the user verifies the code and password match
    // You can call your backend API to update the password
    console.log('Password reset successfully');
    setSuccess(true); // Set success state to true
    navigate('/signin'); // Navigate to the sign-in page
  };

  const handleSendVerificationEmail = async (e) => {
    e.preventDefault();
    // Call the function to send the verification email
    await sendVerificationEmail();
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    // Call the function to verify the code
    await verifyCode();
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    // Call the function to reset the password
    await resetPassword();
  };

  return (
    <>
      <div className="reset-password-container">
        <form onSubmit={step === 1 ? handleSendVerificationEmail : handleVerifyCode} className="reset-form">
          {step === 1 && (
            <>
              <h3 className="reset-header">Reset Password</h3>
              <br />
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <button type="submit" className="reset-button">
                Send Verification Email
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <h3 className="reset-header">Enter Verification Code</h3>
              <br />
              <div className="form-group">
                <input
                  type="text"
                  id="verificationCode"
                  value={enteredCode}
                  onChange={(e) => setEnteredCode(e.target.value)}
                  placeholder="Enter code sent to your email"
                  required
                />
              </div>
              <button type="submit" className="reset-button">
                Verify Code
              </button>
              {error && (
                <div className="error-message">
                  {error}
                </div>
              )}
            </>
          )}
          {step === 3 && (
            <>
              <h3 className="reset-header">Reset Password</h3>
              <br />
              <div className="form-group">
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter your new password"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your new password"
                  required
                />
              </div>
              {error && (
                <div className="error-message">
                  {error}
                </div>
              )}
              {success && (
                <div className="success-message">
                  Password reset successfully
                </div>
              )}
              <br />
              <button type="submit" className="reset-button" onClick={handleResetPassword}>
                Reset Password
              </button>
            </>
          )}
        </form>
        <div className="image-container">
          <Image className="image" src="image.png" thumbnail roundedCircle style={{ border: 'none' }} />
         </div>
      </div>
     </>
   );
 };

 export default ResetPassword;
