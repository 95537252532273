import "./bottom.css";
import { RxArrowRight } from "react-icons/rx";

export default function Bottom() {
  return (
    <div className="overflow-hidden bg-black" style={{
        maxHeight: "100%"
    }}>
      <div className="bottom-container">
        <div className="mx-auto px-4 sm-static pe-sm-6 ps-sm-6 pe-lg-8 ps-lg-8" style={{
            maxWidth: "80rem",
            margin: "auto",
            width: "50%",
        }}>
        <div className="ms-auto">
            <h1 className="display-4 bottom-text" style={{
                maxWidth: "32rem",
                fontWeight: 600,
                color: "white"
            }}>
              Step into the world of style-sync
            </h1>
            <p className="mt-4" style={{
                fontSize: "1.25rem",
                color: "#6c757d"
            }}>
              where we encourage you to break free from the ordinary and embrace
              your own unique fashion journey
            </p>
          </div>

          <a
                href="/"
                className="d-inline-block rounded border border-0 bg-white px-5 py-3 text-center fw-medium text-dark custom-px-14" style={{
                    paddingRight: "3.5rem",
                    paddingLeft: "3.5rem",

                }}
              >
                <div className="text-black text-lg">
                  <RxArrowRight />
                </div>
              </a>
        </div>
      </div>
    </div>
  );
}
