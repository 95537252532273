import React from 'react';
import { Navigate } from 'react-router-dom'; 
import "./SignIn.css";
import Image from 'react-bootstrap/Image';
import ForgotPasswordLink from './ForgotPasswordLink';

export default class SignIn extends React.Component {
  constructor() {
    super();

    this.state = {
      emailData: "",
      passwordData: "",
      submitted: false,
      allValid: false,
      redirectToProducts: false,
      errorMessage: ""
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.passwordValidation = this.passwordValidation.bind(this);
  }

  submitHandler(event) {
    event.preventDefault();
  
    this.setState({
      submitted: true,
      errorMessage: "" // Clear any previous error message
    });
  
    // Extract email and password from state
    const { emailData, passwordData } = this.state;
  
    // Check if email and password are not empty
    if (emailData.length !== 0 && passwordData.length !== 0) {
      // Make a POST request to backend /login endpoint
      fetch('http://138.197.161.82:3000/login', {  // Use localhost if server is running locally
      // fetch('http://localhost:3000/login', {  // Use localhost if server is running locally
        method: 'POST',
        mode:"cors",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailData,
          password: passwordData,
        }),
      })
        .then(response => {
          if (response.status === 401) {
            throw new Error('Invalid credentials');
          } else if (response.status === 404) {
            throw new Error('Email not found');
          }
          return response.json();
        })
        .then(data => {
          // If login is successful, store the token in localStorage or sessionStorage
          localStorage.setItem('token', data.token); // You may use sessionStorage as well
          // Set redirectToProducts to true after successful login
          this.setState({
            redirectToProducts: true,
          });
          setTimeout(() => {
            this.setState({
              allValid: false,
            });
          }, 3000);
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle invalid credentials or other errors
          // Display an error message to the user
          if (error.message === 'Invalid credentials') {
            this.setState({
              errorMessage: 'Invalid credentials.'
            });
          } else if (error.message === 'Email not found') {
            this.setState({
              errorMessage: 'Email not found. Please sign up first.'
            });
          } else {
            // Handle other errors 
            this.setState({
              errorMessage: 'Failed to log in. Please try again later.'
            });
          }
        });
    } else {
      // Handle case where email or password is empty
      if (emailData.length === 0) {
        this.setState({ errorMessage: 'Please enter an email address.' });
      }
      if (passwordData.length === 0) {
        this.setState({ errorMessage: 'Please enter a password.' });
      }
    }
  }
  
  emailValidation(event) {
    this.setState({
      emailData: event.target.value,
    });
  }

  passwordValidation(event) {
    this.setState({
      passwordData: event.target.value,
    });
  }

  render() {
    if (this.state.redirectToProducts) {
      return <Navigate to="/feed" replace={true} />; // Use Navigate for redirection
    }

    return (
      <div>

        <div className="signin-container">
          <div className="form-container">
            <form
              className="signin-form"
              onSubmit={this.submitHandler}
              autoComplete="off"
            >
              {this.state.errorMessage && (
                <div className="error-message">
                  {this.state.errorMessage}
                </div>
              )}
              {this.state.submitted && this.state.allValid && (
                <div className="success-message">
                  Success! You are now signed in
                </div>
              )}
              <input
                id="email"
                onChange={this.emailValidation}
                value={this.state.emailData}
                className="form-field"
                type="text"
                placeholder="Email"
                name="email"
              />
              {this.state.submitted && this.state.emailData.length === 0 && (
                <span id="email-error">Please enter an email address</span>
              )}
              <input
                id="password"
                onChange={this.passwordValidation}
                value={this.state.passwordData}
                className="form-field"
                type="password"
                placeholder="Password"
                name="password"
              />
              <ForgotPasswordLink />
              {this.state.submitted && this.state.passwordData.length === 0 && (
                <span id="password-error">Please enter a password</span>
              )}
              <br></br>
              <button className="form-field" type="submit">
                Sign In
              </button>
            </form>
          </div>
          <div className="image-container">
            <Image className="image" src="image.png" thumbnail roundedCircle style={{ border: 'none' }} />
          </div>
        </div>
      </div>
    );
  }
} 


// {
//   "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI4ZDMwYWJlNi1mNjczLTQyOGQtYmQ2Mi1jMzYwY2M2ZGNkYzgiLCJpYXQiOjE3Mjk1NjAzMjUsImV4cCI6MTcyOTU2MzkyNX0.zVkZjfLNVMeOA6eOxyhNzJL_4zGdug2zrw9tRpkb0PI"
// }