import React, { useEffect, useState } from "react";
import "./itemsCard.css";
import IndividualItemCard from "./individualItemCard";

const ItemsCard = ({ category, setChangedItem }) => {
  const [items, setItems] = useState([]);
  const [itemType, setItemType] = useState(category);
  const API_URL = process.env.REACT_APP_API_URL;
  const [requestedItem, setRequestedItem] = useState(null);

  useEffect(() => {
    if (requestedItem !== null) {
      setChangedItem(requestedItem);
    }
  }, [requestedItem]);

  useEffect(() => {
    fetch(`${API_URL}/items`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("No response");
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        console.log("Error fetching items:", error);
      });
  }, [API_URL]);
  useEffect(() => {
    setItemType(category);
  }, [category]);

  return (
    <div className="items-card">
      {items.map((item) => {
        return (
          <div>
            <IndividualItemCard
              key={item.itemId}
              item={item}
              display={item.category === itemType}
              setRequestedItem={setRequestedItem}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ItemsCard;
