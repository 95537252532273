export const productsData = [
  {
    id: "1",
    name: "variation 1",
    gender: "f",
    description: "variation 1 description",
    blouse: {
      name: "blouse 1",
      image: "image1.png",
      description: "blouse 1 description",
      link: "https://www.zara.com/ca/en/balloon-top-zw-collection-p04437058.html?v1=348233588"
    },
    pants: {
      name: "pants 1",
      image: "image2.png",
      description: "pants 1 description",
      link: "https://www.zara.com/ca/en/jogger-waist-pants-p01608433.html?v1=338125074"
    },
    shoe: {
      name: "shoe 1",
      image: "image3.png",
      description: "shoe 1 description",
      link: "https://www.zara.com/ca/en/faux-patent-leather-ballet-flats-p11217310.html?v1=319502139"
    },
  },
  
  {
    id: "2",
    name: "variation 2",
    gender: "f",
    description: "variation 2 description",
    blouse: {
      name: "blouse 2",
      image: "image4.png",
      description: "blouse 2 description",
      link: "https://www.zara.com/ca/en/crochet-knit-polo-top-p09598018.html?v1=330728761"
    },
    pants: {
      name: "pants 2",
      image: "image5.png",
      description: "pants 2 description",
      link: "https://www.zara.com/ca/en/linen-blend-straight-pants-p09929040.html?v1=332780736"
    },
    shoe: {
      name: "shoe 2",
      image: "image6.png",
      description: "shoe 2 description",
      link: "https://www.zara.com/ca/en/strappy-heeled-shoes-p13258310.html?v1=312372085"
    },
  },
  {
    id: "3",
    name: "variation 3",
    gender: "m",
    description: "variation 3 description",
    blouse: {
      name: "blouse 3",
      image: "menImage1.png",
      description: "blouse 3 description",
      link: "https://www.zara.com/ca/en/printed-seersucker-shirt-p05679410.html?v1=354250404"
    },
    pants: {
      name: "pants 3",
      image: "menImage2.png",
      description: "pants 3 description",
      link: "https://www.zara.com/ca/en/cropped-slim-fit-jeans-p08062455.html?v1=316715560"
    },
    shoe: {
      name: "shoe 3",
      image: "menImage3.png",
      description: "shoe 3 description",
      link: "https://www.zara.com/ca/en/chunky-sneakers-p12201320.html?v1=311282676"
    },
  },
  {
    id: "4",
    name: "variation 4",
    gender: "m",
    description: "variation 4 description",
    blouse: {
      name: "blouse 4",
      image: "menImage4.png",
      description: "blouse 4 description",
      link: "https://www.zara.com/ca/en/watercolor-print-shirt-p04427400.html?v1=345787560"
    },
    pants: {
      name: "pants 4",
      image: "menImage5.png",
      description: "pants 4 description",
      link: "https://www.zara.com/ca/en/skinny-fit-jeans-p00840425.html?v1=316934475"
    },
    shoe: {
      name: "shoe 4",
      image: "menImage6.png",
      description: "shoe 4 description",
      link: "https://www.zara.com/ca/en/suede-tassel-loafers-p12640320.html?v1=311302846"
    },
  },
  {
    id: "5",
    name: "variation 5",
    gender: "m",
    description: "variation 5 description",
    blouse: {
      name: "blouse 5",
      image: "menImage7.png",
      description: "blouse 5 description",
      link: "https://www.zara.com/ca/en/contrasting-embroidered-shirt-p00881404.html?v1=337585791"
    },
    pants: {
      name: "pants 5",
      image: "menImage8.png",
      description: "pants 5 description",
      link: "https://www.zara.com/ca/en/straight-fit-jeans-p08062400.html?v1=339494689"
    },
    shoe: {
      name: "shoe 5",
      image: "menImage9.png",
      description: "shoe 5 description",
      link: "https://www.zara.com/ca/en/casual-suede-loafers-p12645320.html?v1=311287626"
    },
  },
  {
    id: "6",
    name: "variation 6",
    gender: "f",
    description: "variation 6 description",
    blouse: {
      name: "blouse 6",
      image: "image7.png",
      description: "blouse 6 description",
      link: "https://www.zara.com/ca/en/mixed-satin-effect-bustier-p02316595.html?v1=361177505"
    },
    pants: {
      name: "pants 6",
      image: "image8.png",
      description: "pants 6 description",
      link: "https://www.zara.com/ca/en/tuxedo-pants-p02290600.html?v1=364953486"
    },
    shoe: {
      name: "shoe 6",
      image: "image9.png",
      description: "shoe 6 description",
      link: "https://www.zara.com/ca/en/bow-trim-heeled-fabric-sandals-p12326310.html?v1=324080820"
    },
  },
  {
    id: "7",
    name: "variation 7",
    gender: "f",
    description: "variation 7 description",
    blouse: {
      name: "blouse 7",
      image: "image10.png",
      description: "blouse 7 description",
      link: "https://www.zara.com/ca/en/zw-collection-straight-cut-jacket-p02442186.html?v1=361404775"
    },
    pants: {
      name: "pants 7",
      image: "image11.png",
      description: "pants 7 description",
      link: "https://www.zara.com/ca/en/zw-collection-straight-leg-pants-p02486186.html?v1=361404776"
    },
    shoe: {
      name: "shoe 7",
      image: "image12.png",
      description: "shoe 7 description",
      link: "https://www.zara.com/ca/en/bow-trim-satin-effect-heeled-shoes-p12265310.html?v1=349965556"
    },
  },
  {
    id: "8",
    name: "variation 8",
    gender: "m",
    description: "variation 8 description",
    blouse: {
      name: "blouse 8",
      image: "imenImage10.png",
      description: "blouse 8 description",
      link: "https://www.zara.com/ca/en/basic-slim-fit-t-shirt-p05584361.html?v1=315241756"
    },
    pants: {
      name: "pants 8",
      image: "menImage11.png",
      description: "pants 8 description",
      link: "https://www.zara.com/ca/en/cropped-slim-fit-jeans-p08062455.html?v1=316715556"
    },
    shoe: {
      name: "shoe 8",
      image: "menImage12.png",
      description: "shoe 8 description",
      link: "https://www.zara.com/ca/en/retro-running-sneakers-p12342320.html?v1=321151882"
    },
  },
];

