import "./MixAndMatchNewPage.css";
import Carousel from "./mixAndMatchCarousel";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { productsData } from "../utils/data/product";

function MixAndMatchNewPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [previewData, setPreviewData] = useState([]);
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    const selectedProduct = productsData.find((product) => product.id === id);

    setPreviewData(selectedProduct);
  }, []);

  const handleProductsImageData = ({ products, option }) => {
    return products.map((item) => ({
      id: item.id,
      name: item.name,
      description: item.description,
      gender: item.gender,
      [option]: item[option],
    }));
  };

  const handleSelectPreviewItem = (data, option) => {
    // Include link in the preview data
    const newData = { ...previewData, [option]: data[option], link: data[option].link };
    setPreviewData(newData);
  };

  const redirectToLink = () => {
    if (openModal && openModal.link) {
      let url = openModal.link;
      // Check if the link starts with "http://" or "https://"
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        // Prepend "http://" to the link
        url = `http://${url}`;
      }
      window.location.href = url;
    }
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="products-carousel">
          <Carousel
            data={handleProductsImageData({
              products: productsData,
              option: "blouse",
            })}
            onSelectPreview={(data) => handleSelectPreviewItem(data, "blouse")}
            option="blouse"
          />
          <Carousel
            data={handleProductsImageData({
              products: productsData,
              option: "pants",
            })}
            onSelectPreview={(data) => handleSelectPreviewItem(data, "pants")}
            option="pants"
          />
          <Carousel
            data={handleProductsImageData({
              products: productsData,
              option: "shoe",
            })}
            onSelectPreview={(data) => handleSelectPreviewItem(data, "shoe")}
            option="shoe"
          />
        </div>
        <div className="mix-and-match-preview">
          <div className="image-preview-wrapper">
            <div
              style={{
                display: "flex",
                alignItems: "end",
                gap: "1rem",
              }}
            >
              <img
                className="previewMaxAndMatch-image"
                src={`${process.env.PUBLIC_URL}/${previewData?.blouse?.image}`}
                alt={previewData?.blouse?.name}
              />
              <button onClick={() => setOpenModal(previewData?.blouse)}>
                details
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                gap: "1rem",
              }}
            >
              <img
                className="previewMaxAndMatch-image"
                src={`${process.env.PUBLIC_URL}/${previewData?.pants?.image}`}
                alt={previewData?.pants?.name}
              />
              <button onClick={() => setOpenModal(previewData?.pants)}>
                details
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                gap: "1rem",
              }}
            >
              <img
                className="previewMaxAndMatch-image"
                src={`${process.env.PUBLIC_URL}/${previewData?.shoe?.image}`}
                alt={previewData?.shoe?.name}
              />
              <button onClick={() => setOpenModal(previewData?.shoe)}>
                details
              </button>
            </div>
          </div>
          <button onClick={() => navigate("/product")}>
            Submit This Mix And Match
          </button>
          {openModal && (
            <div className="magnifier-overlay" onClick={() => setOpenModal(null)}>
              <div className="magnifier-content" onClick={(e) => e.stopPropagation()}>
                <img
                  src={`${process.env.PUBLIC_URL}/${openModal.image}`}
                  alt={openModal.name}
                />
                <div>
                  <h2>{openModal.name}</h2>
                  <p>{openModal.description}</p>
                  {openModal.link && (
                    <button 
                      onClick={redirectToLink}
                      style={{ background: 'none', border: 'none', cursor: 'pointer',color: 'blue' }}
                    >
                      Link To Product
                    </button>
                  )}
                </div>
                <div onClick={() => setOpenModal(null)} className="no-pointer">❌</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export default MixAndMatchNewPage;
