import React from 'react';
import { Link } from 'react-router-dom';

const ForgotPasswordLink = () => {
  return (
    <div className="forgot-password">
      <Link to="/forgot-password" style={{ color: 'red', textDecoration: 'none', fontSize: 'smaller' }}>
        Forgot Password?
      </Link>
    </div>
  );
};

export default ForgotPasswordLink;
