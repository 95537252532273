import React, { useEffect, useState } from "react";

const IndividualItemCard = ({ item, display, setRequestedItem }) => {
  function handleClick() {
    setRequestedItem({ itemId: item.itemId, itemImage: item.image });
  }
  return (
    <div
      className="individual-item-card"
      style={display ? { display: "block" } : { display: "none" }}
      onClick={handleClick}
    >
      <div className="individual-item-card-desc">
        <h6>{item.name}</h6>
      </div>
      <img src={item.image} alt="" />
    </div>
  );
};

export default IndividualItemCard;
