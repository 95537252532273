import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { productsData } from "../utils/data/product";
import "./Product.css"; // Import the Product CSS file to access the styles

function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [savedProducts, setSavedProducts] = useState([]);

  // Load saved favorites from localStorage when component mounts
  useEffect(() => {
    const savedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setSavedProducts(savedFavorites);
  }, []);

  // Simulated API call to fetch more products
  const fetchProducts = async () => {
    setLoading(true);
    setTimeout(() => {
      const data = productsData.map((item) => item);
      setProducts(data);
      setLoading(false);
    }, 1000);
  };

  // Load more products when user scrolls to the bottom
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [page]);

  // Save product to favorites
  const saveToFavorites = (product) => {
    let savedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const isAlreadyFavorite = savedFavorites.some(item => item.id === product.id);
  
    if (!isAlreadyFavorite) {
      const productToSave = {
        id: product.id,
        name: product.name,
        description: product.description,
        blouseImage: product.blouse.image,
        pantsImage: product.pants.image,
        shoeImage: product.shoe.image,
      };
  
      savedFavorites.push(productToSave);
      localStorage.setItem("favorites", JSON.stringify(savedFavorites));
      setSavedProducts(savedFavorites);
      alert(`${product.name} added to favorites!`);
    } else {
      alert(`${product.name} is already in favorites!`);
    }
  };
  
  // const saveToFavorites = (product) => {
  //   let savedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
  //   const isAlreadyFavorite = savedFavorites.some(item => item.id === product.id);

  //   if (!isAlreadyFavorite) {
  //     savedFavorites.push(product);
  //     localStorage.setItem("favorites", JSON.stringify(savedFavorites));
  //     setSavedProducts(savedFavorites); // Update saved state
  //     alert(`${product.name} added to favorites!`);
  //   } else {
  //     alert(`${product.name} is already in favorites!`);
  //   }
  // };

  // Check if a product is already saved
  const isProductSaved = (productId) => {
    return savedProducts.some(product => product.id === productId);
  };

  return (
    <div>

      <div className="grid-container">
        {products.map((product) => (
          <div className="product-card" key={product?.id}>
            <div className="product-image-list">
              <img
                src={product.blouse.image}
                alt={product.name}
                className="product-img"
              />
              <img
                src={product.pants.image}
                alt={product.name}
                className="product-img"
              />
              <img
                src={product.shoe.image}
                alt={product.name}
                className="product-img"
              />
            </div>
            <div className="card-content">
              <h4>{product?.name}</h4>
              <p>{product?.description}</p>
              <div className="button-group">
                <button
                  className="button"
                  onClick={() => navigate(`/preview/${product?.id}`)}
                >
                  View
                </button>
                {/* Conditional rendering for Save or Saved button */}
                <button
                  className="button"
                  onClick={() => saveToFavorites(product)}
                  disabled={isProductSaved(product.id)} // Disable if already saved
                >
                  {isProductSaved(product.id) ? "Saved" : "Save to Favorites"}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="product-scroll-status">
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
}



export default Product;



