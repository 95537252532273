
import React, { useState, useEffect } from 'react';
import './Profile.css';

export default function Profile() {
  const [user, setUser] = useState({
    fName: '',
    lName: '',
    email: '',
    phone: '',
    address: '',
  });
  const [savedInitials, setSavedInitials] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [showPasswordSection, setShowPasswordSection] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        setErrorMessage('User not authenticated');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/getUser`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setUser({
          fName: data.fName,
          lName: data.lName,
          email: data.email,
          phone: data.phone || '',
          address: data.address,
        });
        setSavedInitials(getInitials(data.fName, data.lName));
      } catch (error) {
        console.error('Error fetching user data:', error);
        setErrorMessage(error.message);
        clearMessageAfterTimeout(setErrorMessage);
      }
    };

    fetchUserData();
  }, [API_URL, token]);

  const getInitials = (firstName, lastName) => {
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  };

  const generateBackgroundColor = (initials) => {
    const colorHash = initials.charCodeAt(0) + initials.charCodeAt(1);
    const colors = [
      '#def1d0', '#bbf3eb', '#bbd9f3', '#cdcdf7', '#e5cdf7', '#f7cdef', '#f7cddb', '#f5f7cd'
    ];
    return colors[colorHash % colors.length];
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const clearMessageAfterTimeout = (setMessage) => {
    setTimeout(() => {
      setMessage('');
    }, 1000); // Clear the message after 1 seconds
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password && password === currentPassword) {
      setErrorMessage('New password must be different from the current password!');
      clearMessageAfterTimeout(setErrorMessage);
      return;
    }

    if (password && password !== confirmPassword) {
      setErrorMessage('Passwords do not match!');
      clearMessageAfterTimeout(setErrorMessage);
      return;
    }

    if (!token) {
      setErrorMessage('User not authenticated');
      clearMessageAfterTimeout(setErrorMessage);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/updateUser`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          fName: user.fName,
          lName: user.lName,
          email: user.email,
          phone: user.phone,
          address: user.address,
          currentPassword: currentPassword || undefined,
          password: password || undefined,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile. Please check the current password or try again later.');
      }

      setSavedInitials(getInitials(user.fName, user.lName)); // Update initials only after saving
      setSuccessMessage('Profile updated successfully!');
      clearMessageAfterTimeout(setSuccessMessage);
    } catch (error) {
      setErrorMessage(error.message);
      clearMessageAfterTimeout(setErrorMessage);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      if (!token) {
        setErrorMessage('User not authenticated');
        clearMessageAfterTimeout(setErrorMessage);
        return;
      }

      try {
        const response = await fetch(`${API_URL}/deleteUser`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete account');
        }

        alert('Your account has been deleted.');
        localStorage.removeItem('token');
        window.location.href = '/';
      } catch (error) {
        setErrorMessage(error.message);
        clearMessageAfterTimeout(setErrorMessage);
      }
    }
  };

  return (
    <div className="profile-container">
      <h1>My Profile</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="profile-picture-section" style={{ textAlign: 'center' }}>
          <div
            className="initials-circle"
            style={{
              backgroundColor: generateBackgroundColor(savedInitials),
              width: '100px',
              height: '100px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              fontSize: '36px',
              color: '#FFFFFF',
              fontWeight: 'bold',
              margin: '0 auto',
            }}
          >
            {savedInitials}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="fName">First Name:</label>
          <input
            type="text"
            id="fName"
            name="fName"
            placeholder="Enter your first name"
            value={user.fName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lName">Last Name:</label>
          <input
            type="text"
            id="lName"
            name="lName"
            placeholder="Enter your last name"
            value={user.lName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={user.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <button
            type="button"
            className="change-password-btn"
            onClick={() => setShowPasswordSection(!showPasswordSection)}
          >
            {showPasswordSection ? 'Hide Password Section' : 'Change Password'}
          </button>
        </div>

        {showPasswordSection && (
          <div className="password-section">
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password:</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">New Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
              />
            </div>
          </div>
        )}

        <div className="button-group">
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleDeleteAccount}
          >
            Delete Account
          </button>
          <button type="submit" className="primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
