import React, { useEffect, useState } from "react";
import SetCard from "../SetCard";
import "./css/feed.css";

const Feed = () => {
  const [sets, setSets] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${API_URL}/sets`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setSets(data.sets);
      })
      .catch((error) => console.error("Error fetching sets:", error));
  }, [API_URL]);

  return (
    <div className="feed-sets-container container d-flex">
      <div className="row">
        {sets.map((set) => (
          <SetCard key={set.setId} set={set} mode={"feed"} />
        ))}
      </div>
    </div>
  );
};

export default Feed;
