import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";

export default function CustomNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    console.log("Logout");
    navigate("/");
  };

  const handleDropdownToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const handleGoBack = () => {
    navigate(-1); // Takes the user back to the previous page
  };

  const isSignInPage = location.pathname === "/signin";
  const isSignUpPage = location.pathname === "/signup";
  const isForgotPasswordPage = location.pathname === "/forgot-password";

  return (
    <Navbar
      bg="black"
      expand="lg"
      className="justify-content-between navbar-dark"
    >
      <Container
        fluid
        style={{
          marginLeft: "5rem",
          marginRight: "5rem",
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
      >
        {/* Conditionally render the Back button on the left side (not on the home page) */}
        {location.pathname !== "/" && (
          <Nav className="me-auto">
            <button
              className="btn btn-outline-light btn-lg custom-back-button mr-3"
              onClick={handleGoBack}
            >
              Back
              <div className="custom-chevron"></div>
            </button>
          </Nav>
        )}

        {/* Conditionally render "Product" link in the top left if on the home page */}
        {location.pathname === "/" && (
          <Nav className="me-auto">
            <a
              href="/feed"
              className="btn btn-dark btn-lg custom-signup-button"
            >
              Explore
              <div className="custom-chevron"></div>
            </a>
          </Nav>
        )}

        {/* Conditionally hide the hamburger menu on restricted pages (Sign In, Sign Up, Forgot Password) */}
        {!isForgotPasswordPage && (
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <MenuIcon style={{ color: "white" }} />
          </Navbar.Toggle>
        )}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto fs-2">
            {/* Wrap the logo with Link component to navigate to the home page */}
            <Link to="/">
              <strong style={{ color: "white", textDecoration: "none" }}>
                style-sync
              </strong>
            </Link>
          </Nav>

          <Nav className="ms-auto justify-content-end">
            {/* Conditionally hide Sign In and Sign Up buttons on specific pages */}
            {!(
              location.pathname === "/favorites"
            ) &&
              (location.pathname === "/mix-and-match" ||
              location.pathname.includes("/preview") ||
              location.pathname.includes("/mix-and-match/") ||
              location.pathname === "/feed" ||
              location.pathname === "/profile" ? (
                <button
                  className="btn btn-dark btn-lg custom-logout-button"
                  onClick={handleLogout}
                >
                  Log out
                  <div className="custom-chevron"></div>
                </button>
              ) : (
                <>
                  {/* Hide Sign In button on the Sign In page */}
                  {!isSignInPage && (
                    <a
                      href="/signin"
                      className="btn btn-dark btn-lg custom-signin-button mr-3"
                    >
                      Sign in
                      <div className="custom-chevron"></div>
                    </a>
                  )}

                  {/* Hide Sign Up button on the Sign Up page */}
                  {!isSignUpPage && (
                    <a
                      href="/signup"
                      className="btn btn-outline-light btn-lg custom-signup-button group-signup"
                    >
                      Sign up
                      <div className="custom-chevron"></div>
                    </a>
                  )}
                </>
              ))}

            {/* Conditionally render the Hamburger Menu based on the current route */}
            {!isForgotPasswordPage && location.pathname !== "/" && (
              <NavDropdown
                title={<MenuIcon style={{ color: "white" }} />}
                id="basic-nav-dropdown"
                show={showDropdown}
                onToggle={handleDropdownToggle}
              >
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="/favorites">
                  Favorite List
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
