import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SetCard from "../SetCard";
import "./css/Preview.css";

const PreviewPage = () => {
  const { state } = useLocation();
  const [set, setSet] = useState(state?.set || null);
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!set) {
      // Fetch the set data if it's not passed through navigation
      fetch(`${API_URL}/set/${id}`)
        .then((res) => {
          if (!res.ok) throw new Error("Network response was not ok");
          return res.json();
        })
        .then((data) => {
          setSet(data); // Assumes the API returns a single set object
        })
        .catch((error) => {
          console.error("Error fetching set data:", error);
        });
    }
  }, [API_URL, id, set]);

  if (!set) {
    return <div>Loading...</div>;
  }

  return (
    <div className="preview-page container d-flex  row justify-content-center">
      <SetCard
        set={set}
        mode={"preview"}
        className="preview-page-set-card col"
      />
      <div className="preview-page-desc col">
        <h4>Created by @</h4>
        <h1>Set Name</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          molestie nibh eget est accumsan ultricies. Ut nisi leo, sodales at
          metus sed, pretium volutpat sem. Vestibulum ante ipsum primis in
          faucibus orci luctus et ultrices posuere cubilia curae; Cras ut
          ullamcorper purus. Mauris faucibus ante sed turpis dignissim cursus
          quis eget lorem. Sed vitae diam id quam venenatis ullamcorper nec at
          tellus. Duis a diam ut sapien volutpat tristique. Aliquam a lacus vel
          ligula maximus faucibus ut sed tellus. Morbi sit amet lobortis nunc,
          dapibus malesuada risus. Suspendisse commodo risus varius semper
          sagittis. Ut id pellentesque lacus. Cras pulvinar malesuada urna id
          ullamcorper. Quisque odio orci, posuere vitae vestibulum non, viverra
          vel nunc. Duis cursus nibh a sapien volutpat pretium. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit.
        </p>
      </div>
    </div>
  );
};

export default PreviewPage;
