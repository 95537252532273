import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './Product.css'; // Reuse the Product.css for consistent styling

export default function FavoriteList() {
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  // Load favorites from localStorage when component mounts
  useEffect(() => {
    const savedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(savedFavorites);
  }, []);

  // Remove product from favorites
  const removeFromFavorites = (productId) => {
    const updatedFavorites = favorites.filter(item => item.id !== productId);
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    alert("Product removed from favorites!");
  };

  return (
    <div>
      <h1>Your Favorite Products</h1>
      <div className="grid-container">
        {favorites.length === 0 ? (
          <p>No favorite items yet.</p>
        ) : (
          favorites.map((product) => (
            <div className="product-card" key={product.id}>
              <div className="product-image-list">
                <img
                  src={product.blouseImage}
                  alt={product.name}
                  className="product-img"
                />
                <img
                  src={product.pantsImage}
                  alt={product.name}
                  className="product-img"
                />
                <img
                  src={product.shoeImage}
                  alt={product.name}
                  className="product-img"
                />
              </div>
              <div className="card-content">
                <h4>{product.name}</h4>
                <p>{product.description}</p>
                <div className="button-group">
                  <button
                    className="button"
                    onClick={() => navigate(`/preview/${product.id}`)}
                  >
                    View
                  </button>
                  <button
                    className="button button-delete"
                    onClick={() => removeFromFavorites(product.id)}
                  >
                    Delete from Favorites
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
